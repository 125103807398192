.navbar {
  background:#F97300;
}
.nav-link , .navbar-brand {
  color: #f4f4f4; cursor: pointer;
}
.nav-link {
  margin-right: 1em !important;
}
.nav-link:hover {
  color: #000000;
}
.navbar-collapse {
  justify-content: flex-end;
}
.navbar-toggler {
  background:#fff !important;
}

.active {
    border-bottom: 1px solid #333;
}

.ml-auto .dropdown-menu {
  left: auto !important;
  right: 0px;
}

.circle-brand {
    background: #F97300;
    padding: 7px;
    border-radius: 50%;
    border-color: black;
    border-style: solid;
    border-width: 2px;

}
