.blog > .container {
  margin: auto;
}

.blog .card {
  box-shadow: 0 0 20px #ccc;
}

.blog .card:hover {
  box-shadow: 0 0 50px #ccc;
}

.blog .card img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 12em;
}

.blog .card-title {
  color:#F97300;
}

.blog .card-body {
  padding: 1em;
  font-size: 1.2em;
}
