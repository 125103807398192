.experience {
  padding: 1em;
  background-image: url('./images/journey.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.experience .card {
  box-shadow: 0 0 20px #ccc;
}

.experience .card img {
  display: block;
  margin: auto;
  height: 10em;
}

.experience .card-title {
  color:#F97300;

}

.experience .card-body {
  padding: 1em;
}
