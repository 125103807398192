@import url(https://fonts.googleapis.com/css?family=Raleway);
@import url(https://fonts.googleapis.com/css?family=Rubik);
@import url(https://fonts.googleapis.com/css?family=Trade+Winds);
@import url(https://fonts.googleapis.com/css?family=Quicksand);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,h1,h2,h3,h4,h5,h6,a{
 /*font-family: "Rubik";*/
 font-family: 'Quicksand';
}

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.header-margin {
  margin-top: 75px;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.section-title {
  color: #DDDDDD;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 60px;
  letter-spacing: 0.1em;
  font-family: 'Quicksand';
  font-weight: bold;
  font-size: 4vw;
}

@media screen and (min-width: 1000px) {
  .section-title {
     font-size: 30px;
  }
}

.navbar {
  background:#F97300;
}
.nav-link , .navbar-brand {
  color: #f4f4f4; cursor: pointer;
}
.nav-link {
  margin-right: 1em !important;
}
.nav-link:hover {
  color: #000000;
}
.navbar-collapse {
  justify-content: flex-end;
}
.navbar-toggler {
  background:#fff !important;
}

.active {
    border-bottom: 1px solid #333;
}

.ml-auto .dropdown-menu {
  left: auto !important;
  right: 0px;
}

.circle-brand {
    background: #F97300;
    padding: 7px;
    border-radius: 50%;
    border-color: black;
    border-style: solid;
    border-width: 2px;

}

.footer {
  color: #F97300;
  background-color: #eeeeee;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.header {
  background-image: url(/static/media/background.2c2e5f5d.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  /* background: rgba(244, 244, 244, 0.30); */
}

.circle-icon {
    background: #F97300;
    padding:20px;
    border-radius: 50%;
}
.circle-icon:hover {
    padding:12px;
    font-size: 48px;
}

.greetings {
  margin: auto;
  padding: 2em;
}

.greetings h1 {
  font-size: 3vw;
  color: #DDDDDD;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 60px;
  letter-spacing: 0.1em;
  font-family: 'Quicksand';
  font-weight: bold;
}

@media screen and (min-width: 1200px) {
  .greetings h1 {
     font-size: 40px;
  }
}

.on-top {
  z-index: 10;
}

.about .myface {
  border-radius: 50%;
  max-height: 250px;
  max-width: 250px;
}
.blah {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.about .desc {
  line-height: 1.5;
  font-size: 1.2em;
  color:#000;
  padding: 30px;
  border-radius: 10px;
  background: rgba(244, 244, 244, 0.70);
  font-family: 'Quicksand';
  font-weight: bold;
}

.img-progress img {
  width: 100px;
}

.blog > .container {
  margin: auto;
}

.blog .card {
  box-shadow: 0 0 20px #ccc;
}

.blog .card:hover {
  box-shadow: 0 0 50px #ccc;
}

.blog .card img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 12em;
}

.blog .card-title {
  color:#F97300;
}

.blog .card-body {
  padding: 1em;
  font-size: 1.2em;
}

.blog {
  padding: 1em;
  background-image: url(/static/media/writing.dc6b86f8.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.portfolio {
  padding: 1em;
  background-image: url(/static/media/code.44640c9f.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.orange-arrows {
  color: orange;
}

.carousel-text {
  color: orange;
}

.carousel-indicators {
  bottom: 19px !important;
  margin: 0px;
  height: 0px;
}

.carousel-indicators ol {
  width: 100%;
}

.carousel-indicators li{
    height: 30px !important;
    border-radius: 15px !important;
    width: 25%;
}

.carousel-footer {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.experience {
  padding: 1em;
  background-image: url(/static/media/journey.04064e59.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.experience .card {
  box-shadow: 0 0 20px #ccc;
}

.experience .card img {
  display: block;
  margin: auto;
  height: 10em;
}

.experience .card-title {
  color:#F97300;

}

.experience .card-body {
  padding: 1em;
}

.contact-form {
 margin: 6em 0;
 position: relative;
}
.contact-form h1{
 padding:2em 1px;
 color: #F97300;
}
.contact-form .right{
 max-width: 600px;
}
.contact-form .right .btn-secondary{
 background:  #F97300;
 color: #fff;
 border:0;
}
.contact-form .right .form-control::-webkit-input-placeholder{
 color: #888;
 font-size: 16px;
}
.contact-form .right .form-control::-ms-input-placeholder{
 color: #888;
 font-size: 16px;
}
.contact-form .right .form-control::placeholder{
 color: #888;
 font-size: 16px;
}

.skills {
  padding: 1em;
  background-color: #DDDDDD;
}

.skill-icon {
  font-size: 5em;
  margin: 10px;
}
.skill-icon:hover {
  border: 3px solid #888888;
  border-radius: 10px;
  margin: 7px;
}

.skill-list {
  list-style: none;
}

.skill-list li {
  margin-top: 10px;
  float: left;
}

.education {
  padding: 1em;
  background-color: #DDDDDD;
  font-size: 1.2em;
}

.publications {
  padding: 1em;
  background-color: #DDDDDD;
}

.fancy-first-letter {
  float: left;
  padding: .25em .05em .25em 0;
  font-size: 5em;
  line-height: 0.4em;
}

