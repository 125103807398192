.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.header {
  background-image: url('./images/background.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  left: 0;
  top: 0;
  /* background: rgba(244, 244, 244, 0.30); */
}

.circle-icon {
    background: #F97300;
    padding:20px;
    border-radius: 50%;
}
.circle-icon:hover {
    padding:12px;
    font-size: 48px;
}

.greetings {
  margin: auto;
  padding: 2em;
}

.greetings h1 {
  font-size: 3vw;
  color: #DDDDDD;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 60px;
  letter-spacing: 0.1em;
  font-family: 'Quicksand';
  font-weight: bold;
}

@media screen and (min-width: 1200px) {
  .greetings h1 {
     font-size: 40px;
  }
}

.on-top {
  z-index: 10;
}

.about .myface {
  border-radius: 50%;
  max-height: 250px;
  max-width: 250px;
}
.blah {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.about .desc {
  line-height: 1.5;
  font-size: 1.2em;
  color:#000;
  padding: 30px;
  border-radius: 10px;
  background: rgba(244, 244, 244, 0.70);
  font-family: 'Quicksand';
  font-weight: bold;
}

.img-progress img {
  width: 100px;
}
