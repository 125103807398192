.skills {
  padding: 1em;
  background-color: #DDDDDD;
}

.skill-icon {
  font-size: 5em;
  margin: 10px;
}
.skill-icon:hover {
  border: 3px solid #888888;
  border-radius: 10px;
  margin: 7px;
}

.skill-list {
  list-style: none;
}

.skill-list li {
  margin-top: 10px;
  float: left;
}
