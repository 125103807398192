@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Rubik');
@import url('https://fonts.googleapis.com/css?family=Trade+Winds');
@import url('https://fonts.googleapis.com/css?family=Quicksand');

html,h1,h2,h3,h4,h5,h6,a{
 /*font-family: "Rubik";*/
 font-family: 'Quicksand';
}

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.header-margin {
  margin-top: 75px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-title {
  color: #DDDDDD;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 60px;
  letter-spacing: 0.1em;
  font-family: 'Quicksand';
  font-weight: bold;
  font-size: 4vw;
}

@media screen and (min-width: 1000px) {
  .section-title {
     font-size: 30px;
  }
}
