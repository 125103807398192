.portfolio {
  padding: 1em;
  background-image: url('./images/code.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.orange-arrows {
  color: orange;
}

.carousel-text {
  color: orange;
}

.carousel-indicators {
  bottom: 19px !important;
  margin: 0px;
  height: 0px;
}

.carousel-indicators ol {
  width: 100%;
}

.carousel-indicators li{
    height: 30px !important;
    border-radius: 15px !important;
    width: 25%;
}

.carousel-footer {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
}
